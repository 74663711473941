// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl1: 'http://localhost:4200',
  // API_URL: 'https://apisupply.previewonline.website/api/',
  // API_URL: 'https://test.supplierscave.com/v1/api/',
  API_URL: 'https://webtechnomind.in/supplierscave/v1/api/',
  vendorId: 166,
  // IMG_URL: 'http://apisupply.previewonline.website/public/upload/',
  // IMG_URL: 'https://test.supplierscave.com/v1/public/upload/',
  IMG_URL: 'https://webtechnomind.in/supplierscave/v1/public/upload/',
  roleList:   [
    { item_id: 1, item_text: 'Buyer' },
    { item_id: 2, item_text: 'Supplier' },
    { item_id: 3, item_text: 'Consultant' },
  ],
   algoliya:{
     appId: 'Q9C2SXQKQC',
     apiKey:'09c854711dca646aa25a63cc41459f9d',
     indexName:'Inventory',
     urlSync: false
   },
   directoryAlgoliya:{
    appId: 'Q9C2SXQKQC',
    apiKey:'09c854711dca646aa25a63cc41459f9d',
    indexName:'Directory',
    urlSync: false
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
