import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { ADMINROUTES } from './admin-sidebar-items';
import { AuthService } from 'src/app/core/service/auth.service';
import { BUYERROUTES } from './buyer-sidebar-items';
import { SUPPLIERROUTES } from './supplier-sidebar-items';
import { environment } from 'src/environments/environment';
import { UserServiceService } from 'src/app/services/user-service.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  routerObj = null;
  currentRoute: string;
  userInfo: any;
  userRole: any;
  roleList: any = environment.roleList;
  selectedRole: any;
  imageBaseUrl: any = environment.IMG_URL;
  userData: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router, private _userServiceService: UserServiceService,
  ) {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });

    this._userServiceService.userResClick.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      } else {
        this.userData = null;
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const activeClass = parentElement.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }
  ngOnInit() {


    this.renderer.removeClass(this.document.body, 'logo-white');
    let userInfo: any = localStorage.getItem("userClick");
    this.userInfo = JSON.parse(userInfo);
    this.userRole = this.userInfo.response_data.role_id.split(',');
    var roleId = localStorage.getItem("roleId");
    this.selectedRole = localStorage.getItem("roleId");
    if (this.userInfo.response_data.role_text && roleId == '0') {
      this.sidebarItems = ADMINROUTES.filter((sidebarItem) => sidebarItem);
    } else if (this.userInfo.response_data.role_text && roleId == '1') {
      this.sidebarItems = BUYERROUTES.filter((sidebarItem) => sidebarItem);
    } else if (this.userInfo.response_data.role_text && roleId == '2') {
      this.sidebarItems = SUPPLIERROUTES.filter((sidebarItem) => sidebarItem);
    }
    else {
      this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;

    if (this.selectedRole == 0) {
      this.darkThemeBtnClick();
      this.selectTheme('black');
    } else if (this.selectedRole == 1) {
      this.selectTheme('green');
    } else if (this.selectedRole == 2) {
      this.selectTheme('blue');
    } else if (this.selectedRole == 3) {
      this.selectTheme('orange');
    } else {
      this.selectTheme('purple');
    }
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  darkThemeBtnClick() {


    this.renderer.addClass(this.document.body, 'dark');
    this.renderer.addClass(this.document.body, 'submenu-closed');
    this.renderer.addClass(this.document.body, 'menu_dark');
    this.renderer.addClass(this.document.body, 'logo-black');

    this.renderer.removeClass(this.document.body, 'light');
    this.renderer.removeClass(this.document.body, 'submenu-closed');
    this.renderer.removeClass(this.document.body, 'menu_light');
    this.renderer.removeClass(this.document.body, 'logo-white');


  }

  selectTheme(e) {
    // const prevTheme = this.elementRef.nativeElement
    //   .querySelector('.settingSidebar .choose-theme li.active')
    //   .getAttribute('data-theme');
    // this.renderer.removeClass(this.document.body, 'theme-' + prevTheme);

    if (e) {
      this.renderer.removeClass(this.document.body, 'theme-black');
      this.renderer.removeClass(this.document.body, 'theme-green');
      this.renderer.removeClass(this.document.body, 'theme-blue');
      this.renderer.removeClass(this.document.body, 'theme-purple');
      this.renderer.removeClass(this.document.body, 'theme-orange');
      this.renderer.addClass(this.document.body, 'theme-' + e);
    }


  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  roleText(e: any) {
    var contract = this.roleList.filter(x => x.item_id == e);
    return contract[0].item_text;
  }
}
