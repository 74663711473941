<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="/">
        <img *ngIf="!userData?.image" src="assets/images/logo.png" alt="" />
        <img *ngIf="userData?.image" src="{{userData?.image}}" alt="" />
        <span class="logo-name">SuppliersCave</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">

        <!-- #END# Notifications-->
        <li *ngIf="userRole.length >1" class="nav-item user_profile" ngbDropdown>
              <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
                <span>{{roleText(selectedRole) }}</span>
              </div>
              <div ngbDropdownMenu class="notification-dropdown pullDown">
                <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
                  <ul class="menu">
                    <li class="body">
                      <ul class="user_dw_menu selectboxdesh">
                        <ng-container *ngIf="userRole.length >1">
                        <li *ngFor="let guest of userRole; let i = index;">
                          <a (click)="changeRole(guest)" >
                            <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{roleText(guest)}}
                          </a>
                        </li>
                        </ng-container>


                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
        <!-- #END# Tasks -->
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!-- <form [formGroup]="profileForm">
          <li>
            <mat-form-field class="example-full-width selectboxdesh">
              <mat-select formControlName="roleName">
                <mat-option value="Buyer">Buyer</mat-option>
                <mat-option value="Supplier">Supplier</mat-option>
                <mat-option value="Consultant">Consultant</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
        </form> -->
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
        <li class="nav-item btnNotification" ngbDropdown>
          <!-- <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button> -->
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1">Read
              All Notifications</a>
          </div>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img *ngIf="!userData?.image" src="assets/images/user.png" class="rounded-circle" width="32"
              height="32" alt="User">
            <img *ngIf="userData?.image" src="{{userData?.image}}" class="rounded-circle"
              width="32" height="32" alt="User">

            <span>{{userData?.name}}</span>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a routerLink="/user-pages/profile" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <!-- <ng-container *ngIf="userRole.length >1">
                    <li *ngFor="let guest of userRole; let i = index;">
                      <a (click)="changeRole(guest)" >
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>{{roleText(guest)}}
                      </a>
                    </li>
                    </ng-container> -->
                    <!-- <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>