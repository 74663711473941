import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  private userSubject: BehaviorSubject<any>;
  public userClick: Observable<any>;
  private userResSubject: BehaviorSubject<any>;
  public userResClick: Observable<any>;
  constructor(private _http: HttpClient) {
    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userClick') || '{}'));
    this.userClick = this.userSubject.asObservable();

    this.userResSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userResClick') || '{}'));
    this.userResClick = this.userResSubject.asObservable();

  }

  userRegister(data: any) {

    // return this._http.post(environment.API_URL + "regsiter",data); 
    return this._http
      .post(environment.API_URL + "regsiter", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  userLogin(data: any) {
    return this._http
      .post(environment.API_URL + "login", data, {})
      .pipe(map((response: any) => {
        if (response && response.api_token) {
          localStorage.setItem("token", response.api_token);
        } if (response && response.response_data_role) {
          localStorage.setItem("roleId", response.response_data_role);
        }
        if (response && response.response_data) {
          localStorage.setItem("userId", response.response_data.id);

          localStorage.setItem("userName", response.response_data.name);
          this.storeUserData(response);
          this.storeUserResponse(response.response_data);
        }
        return response;
      }));
  }

  getUserDetails(data: any) {
    return this._http
      .post(environment.API_URL + "user-details", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  updateUserDetails(data: any) {
    return this._http
      .post(environment.API_URL + "update-user-details", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  updateAllUserDetails(data: any) {
    return this._http
      .post(environment.API_URL + "update-all-user-details", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  changeUserPassword(data: any) {
    return this._http
      .post(environment.API_URL + "update-user-password", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  storeUserData(data: any) {
    localStorage.setItem('userClick', JSON.stringify(data));
    this.userSubject.next(data);
  }

  storeUserResponse(data: any) {
    localStorage.setItem('userResClick', JSON.stringify(data));
    this.userResSubject.next(data);
  }

  placeOrder(data: any) {
    return this._http
      .post(environment.API_URL + "place-order", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  getOrder(data: any) {
    return this._http
      .post(environment.API_URL + "get-order", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  logOut() {
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("roleId");
    localStorage.removeItem("userName");
    localStorage.removeItem('userClick');
    localStorage.removeItem('userResClick');
    this.userSubject.next(null);
    this.userResSubject.next(null);
  }

  getProfile(data: any) {
    return this._http
      .post(environment.API_URL + "get-profile", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  updateProfile(data: any) {
    return this._http
      .post(environment.API_URL + "update-profile", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  updateProfileImage(data: any) {
    return this._http
      .post(environment.API_URL + "update-profile-avatar", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  changePassword(data: any) {
    return this._http
      .post(environment.API_URL + "update-password", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  getBookATableList(data: any) {
    return this._http
      .post(environment.API_URL + "get-form", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }
  submitReviewRatting(data: any) {
    return this._http
      .post(environment.API_URL + "update-review", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  submitCoupon(data: any) {
    return this._http
      .post(environment.API_URL + "apply-coupon", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteCoupon(data: any) {
    return this._http
      .post(environment.API_URL + "remove-coupon", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  socialLoginRegistration(data: any) {
    return this._http
      .post(environment.API_URL + "social-login", data, {})
      .pipe(map((response: any) => {
        localStorage.setItem("token", response.api_token);
        localStorage.setItem("userId", response.response_data.id);
        localStorage.setItem("roleId", response.response_data.role_id);
        localStorage.setItem("userName", response.response_data.name);
        this.storeUserData(response);
        return response;
      }));
  }

  cancelOrder(data: any) {
    return this._http
      .post(environment.API_URL + "update-order-status", data, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

}
